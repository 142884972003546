<template>
  <div class="card__header">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardHeader'
}
</script>

<style scoped lang="scss">

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  @include phone {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
