<template>
  <section class="login">
    <div class="container">
      <div class="login__logo">
        <router-link to="/">
          <img src="@/assets/uploads/icon/logo.svg" alt="Логотип"/>
        </router-link>
      </div>
      <div class="login__container">
        <h3>
          Вход в личный кабинет
        </h3>
        <PhoneForm v-if="!userPhone"/>
        <div class="login__recovery-pass">
          Забыли пароль? <a href="">Восстановить</a>.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PhoneForm from '@/components/pages/auth/PhoneForm.vue'
import { computed, ref } from 'vue'
export default {
  name: 'AuthView',
  components: { PhoneForm },
  setup () {
    const userPhone = ref('')

    const isBrowser = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform !== 'android'
    })

    return {
      userPhone,
      isBrowser
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  min-height: 100vh;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, .1);
    padding: 20px;
    border-radius: 6px;
    background: #FFFFFF;
    @include desktop {
      margin: 0 auto;
      max-width: 800px;
      padding: 48px;
    }
  }

  &__logo {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    font-size: 30px;

    img {
      height: 60px;
      width: 230px;
    }
  }
}

.login__recovery-pass {
  a {
    color: $orange;
    text-decoration: underline;
  }
}
</style>
