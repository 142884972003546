<template>
  <!-- Modal -->
  <div id="hamburgerModal" aria-hidden="true" aria-labelledby="hamburgerModal" class="modal fade2 hamburger"
       tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable hamburger__dialog">
      <div class="modal-content hamburger__content">
        <div class="modal-body hamburger__body">
          <nav class="sidebar__nav">
            <ul id="accordionExample" class="accordion">
              <li aria-label="Close" class="hamburger__item" data-bs-dismiss="modal">
                <router-link v-wave class="hamburger__link" to="/contacts">
                  Контакты
                </router-link>
              </li>
              <li aria-label="Close" class="hamburger__item" data-bs-dismiss="modal">
                <router-link v-wave class="hamburger__link" to="/calls">
                  Звонки
                </router-link>
              </li>
              <li class="hamburger__item">
                <h2 id="headingOne" class="accordion-header">
                  <button v-wave="{color: '#ff8330'}" aria-controls="collapseOne" aria-expanded="true"
                          class="accordion-button collapsed"
                          data-bs-target="#collapseOne" data-bs-toggle="collapse"
                          type="button"
                  >
                    Визиты
                  </button>
                </h2>
                <div id="collapseOne" aria-labelledby="headingOne" class="accordion-collapse collapse"
                     data-bs-parent="#accordionExample">
                  <div aria-label="Close" class="accordion-body hamburger__accordion" data-bs-dismiss="modal">
                    <router-link v-wave class="hamburger__link" to="/visit-fact">
                      Факт
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/visit-plan">
                      План
                    </router-link>
                  </div>
                </div>
              </li>
              <li aria-label="Close" class="hamburger__item" data-bs-dismiss="modal">
                <router-link v-wave class="hamburger__link" to="/clients">
                  Клиенты
                </router-link>
              </li>
              <li aria-label="Close" class="hamburger__item" data-bs-dismiss="modal">
                <router-link v-wave class="hamburger__link" to="/offers">
                  Предложения
                </router-link>
              </li>
              <li class="hamburger__item">
                <h2 id="headingTwo" class="accordion-header">
                  <button v-wave="{color: '#ff8330'}" aria-controls="collapseTwo" aria-expanded="true"
                          class="accordion-button collapsed"
                          data-bs-target="#collapseTwo" data-bs-toggle="collapse"
                          type="button"
                  >
                    Отчеты
                  </button>
                </h2>
                <div id="collapseTwo" aria-labelledby="headingOne" class="accordion-collapse collapse"
                     data-bs-parent="#accordionExample">
                  <div aria-label="Close" class="accordion-body hamburger__accordion" data-bs-dismiss="modal">
                    <router-link v-wave class="hamburger__link" to="/reports/kpi">
                      KPI
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/reports/bpc">
                      Параметры АКБ
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/reports/debt">
                      Дебиторка
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/reports/charts">
                      Аналитика SFA
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/reports/visits-map">
                      Карта визитов и точек продаж
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/reports/points-map">
                      Карта точек продаж
                    </router-link>
                  </div>
                </div>
              </li>
              <li class="hamburger__item">
                <h2 id="headingThree" class="accordion-header">
                  <button v-wave="{color: '#ff8330'}" aria-controls="collapseThree" aria-expanded="true"
                          class="accordion-button collapsed"
                          data-bs-target="#collapseThree" data-bs-toggle="collapse"
                          type="button"
                  >
                    Задачи
                  </button>
                </h2>
                <div id="collapseThree" aria-labelledby="headingOne" class="accordion-collapse collapse"
                     data-bs-parent="#accordionExample">
                  <div aria-label="Close" class="accordion-body hamburger__accordion" data-bs-dismiss="modal">
                    <router-link v-wave class="hamburger__link" to="/task/add" v-if="hasRights">
                      Добавить задачу
                    </router-link>
                    <router-link v-wave class="hamburger__link" to="/task">
                      Список
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HamburgerModal',
  props: {
    hasRights: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.hamburger__dialog {
  display: flex;
  justify-content: end;
  height: 100%;

  font-size: 16px;
  margin: 0 0 0 auto;
}

.hamburger__body {
  padding: 10px 0;
}

.hamburger__content {
  background: #ffffff;
  width: 60%;
  height: 100%;
  border-radius: 0;
}

.hamburger__link {
  display: block;
  padding: 16px 20px;
}

.hamburger__link:hover, .accordion-button:hover {
  background-color: #f6f6f6;
}

.hamburger__accordion {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.fade2 {
  transform: translateX(100vw);
  opacity: 0;
  transition: all .3s linear;
  display: block !important;
}

.fade2.show {
  opacity: 1;
  transform: translateX(0);
}

.accordion-button:not(.collapsed) {
  color: $orange;
  background-color: $orange-light-3;
  box-shadow: unset;
}

.accordion-button:not(.collapsed)::after {
  filter: invert(53%) sepia(98%) saturate(1385%) hue-rotate(339deg) brightness(107%) contrast(101%);
}

.accordion-button:focus {
  border-color: #FFFFFF;
  box-shadow: unset;
}
</style>
