<template>
  <form v-if="!loading" action="" class="form-phone" @submit.prevent="loginUser"  @keyup.enter.prevent="loginUser">
    <div class="form-input__group" :class="{ 'form-group--error': v$.login.$error }">
      <label for="login">Логин</label>
      <input
        type="text"
        id="login"
        v-model="userInfo.login"
      />
      <div v-if="v$.login.$error" class="error help-block">
        Необходимо ввести логин
      </div>
    </div>
    <div class="form-input__group" :class="{ 'form-group--error': v$.password.$error }">
      <label for="login">Пароль</label>
      <input
        type="password"
        id="login"
        v-model="userInfo.password"
      />
      <div v-if="v$.password.$error" class="error help-block">
       Неверно введен логин или пароль.
      </div>
    </div>
    <div v-if="requestError" class="error help-block">
      Неверно введен логин или пароль.
    </div>
    <CustomButton tag="button" type="submit" marginBottom="20px">
      Войти
    </CustomButton>
  </form>

  <TheLoader v-if="loading"/>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { required } from '@vuelidate/validators'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useVuelidate } from '@vuelidate/core'
import { inject, reactive, ref, watch } from 'vue'
import { useAuthUser } from '@/composables/useAuthUser'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
export default {
  name: 'PhoneForm',
  components: { TheLoader, CustomButton },
  setup (props, { emit }) {
    const loading = ref(false)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const requestError = ref(false)
    const toast = inject('toast')
    const router = useRouter()
    const responseErrorMsg = ref('Сервис временно недоступен. Повторите попытку позже')
    const userInfo = reactive({
      login: '',
      password: ''
    })
    const rules = {
      login: { required },
      password: { required }
    }
    const v$ = useVuelidate(rules, userInfo)
    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }
    watch(userInfo, () => {
      requestError.value = false
    })
    const loginUser = async () => {
      v$.value.$touch()
      if (v$.value.$error) {
        return
      }
      loading.value = true

      try {
        await useAuthUser(userInfo.login, userInfo.password)
        requestError.value = false
        await router.push({ name: 'home' })
        showHelloToaster()
      } catch (err) {
        requestError.value = true
      } finally {
        loading.value = false
      }

      if (userToken.value) {
        window.Fingerprint.registerBiometricSecret({
          description: 'Для работы с личным кабинетом надо пройти аутентификацию',
          secret: userToken.value,
          invalidateOnEnrollment: true,
          disableBackup: true // always disabled on Android
        })
      }
    }

    const onDeviceReady = () => {
      new Promise((resolve, reject) => {
        window.Fingerprint.isAvailable((suc) => {
          window.Fingerprint.loadBiometricSecret({
            description: 'Для работы с личным кабинетом надо пройти аутентификацию',
            disableBackup: true // always disabled on Android
          }, (suc) => {
            resolve(suc)
          }, (err) => {
            reject(err)
          })
        }, (err) => {
          reject(err)
        })
      }).then(suc => {
        router.push({
          name: 'home'
        })
      }).catch(() => {
        // router.push({
        //   name: 'login'
        // })
      })
    }
    onDeviceReady()
    return {
      userInfo,
      loginUser,
      v$,
      requestError,
      loading,
      responseErrorMsg
    }
  }
}
</script>

<style scoped lang="scss">
.form-input__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 15px;

  label {
    font-size: 16px;
    font-weight: 400;
    color: #212529;
  }

  input {
    display: block;
    width: 100%;
    min-width: 250px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}
.error {
  margin-bottom: 10px;
  color: darkred;
}
.form-phone {
  &__group {

    & .help-block {
      margin-bottom: 12px;
    }
  }

  &__label {
    color: $blue;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  &__inputs {
    margin-bottom: 12px;
  }

  &__sms {
    margin-bottom: 25px;
  }

  &__rules {
    display: block;
    text-align: center;
    color: $blue;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
}

</style>
