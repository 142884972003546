<template>
  <teleport to="#app">
    <transition name="fade">
      <div class="modal-edit" v-if="modalIsOpen">
        <div class="modal-edit__wrapper" v-click-away="onClickAway">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" @click.prevent="$emit('modal-close')">
                <svg>
                  <use xlink:href="@/assets/uploads/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
            <slot name="body">
              <div class="modal-body">
                Дефолтный контент модального окна
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  name: 'ClientsEditModal',
  emits: ['modalClose'],
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup (props, { emit }) {
    const onClickAway = () => {
      emit('modal-close')
    }
    const handleKeydown = (event) => {
      if (event.key === 'Escape') {
        emit('modal-close')
      }
    }
    onMounted(() => {
      document.addEventListener('keydown', handleKeydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown)
    })
    return {
      onClickAway
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.modal-edit {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 1000;
  display: flex;
  align-items: center;
}

.modal-edit__wrapper {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  min-width: 400px;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 30px 50px;
  position: relative;
  @media (max-width: 575px) {
    max-width: 95%;
    min-width: 95%;
    padding: 20px 20px 40px;
  }
}

</style>
