<template>
  <div class="event-log">
    <div class="event-log__list">
      <ul>
        <li v-for="item in eventsData.events" :key="item.id">
          <img :src="item.icon" alt="">
          <div class="event-log__item-text">
            <p><span>{{ item.created_at }} | {{ item.author.fio }} - {{ item.type }}</span></p>
            <p><a href="" v-if="item.id">#{{ item.id }}</a></p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'EventLog',
  components: { },
  props: {
    eventsData: {
      type: Array,
      require: true
    }
  },
  setup (props, { emit }) {
    const countContacts = ref('')
    const handleSelectedCountUpdate = (value) => {
      countContacts.value = value
      emit('on-page', value)
    }
    return {
      handleSelectedCountUpdate,
      countContacts
    }
  }
}
</script>
<style scoped lang="scss">
.event-log__list {
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    li {
      display: flex;
      align-items: center;
      gap: 15px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.event-log__item-text {
  p {
    padding: 4px 0;
    span {
      font-weight: 600;
    }
    a {
      color: $orange;
      text-decoration: underline;
    }
  }
}
</style>
