<template>
  <component
    :is="tag"
    class="custom-btn"
    :class="{'custom-btn--white': white}"
    :href="tag === 'a' ? href : null"
    :type="type"
  >
    <div class="custom-btn__wrapper">
      <slot name="img">
        <div></div>
      </slot>

      <slot/>
    </div>
  </component>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    white: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'a'
    },
    bgColor: {
      type: String,
      required: false,
      default: '#BAC7DE'
    },
    type: {
      type: [String, null],
      default: '',
      required: false
    },
    color: {
      type: String,
      default: '#002856',
      required: false
    },
    href: {
      type: String,
      default: '',
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.custom-btn {
  margin-bottom: 12px;
  display: block;
  width: 100%;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border-radius: 30px;
  background: #ff8330;
  outline: none;
  border: none;
  font-weight: 500;
  &:active {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &--white {
    background: #FFFFFF;
    border: 1px solid #002856;

    &:active {
      background: #002856;
      color: #fff;
    }
  }
}
</style>
