<template>
  <button :class="{ active: isActive }" class="header-hamburger" data-bs-target="#hamburgerModal" data-bs-toggle="modal"
          type="button"
          @click="isActive = !isActive">
    <span></span>
  </button>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'HamburgerButton',

  setup () {
    const isActive = ref(false)

    return {
      isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.header-hamburger {
  border: unset;
  position: relative;
  width: 25px;
  height: 15px;
  transition: transform .2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $grey;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $grey;
    bottom: 0;
    left: 0;
  }

  & span {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $grey;
    top: 50%;
    left: 0;
  }
}

.header-hamburger:after, .header-hamburger:before, .header-hamburger span {
  transition: transform .3s ease-in-out;
}

.header-hamburger:active {
  transform: translate(1px, 1px);
}
</style>
