import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

export async function useAuthUser (login, password) {
  const userStore = useUserStore()
  const { setUserToken, setUser } = userStore

  try {
    const basicAuth = btoa(login + ':' + password)
    const response = await axios.post('/v1/auth/login', {},
      {
        headers: {
          Authorization: `Basic ${basicAuth}`,
          'Content-Type': 'application/json'
        }
      })
    if (response.data.success === true) {
      setUserToken(response.data.data.token)
      await setUser(response.data.data.token)
      return response
    }
  } catch (err) {
    throw new Error(err)
  }
}
