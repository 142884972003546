import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export const authBrowser = async () => {
  const platform = window?.device?.platform.toLowerCase()
  if (platform === 'android') {
    return
  }
  const userStore = useUserStore()
  const { userTokenStorage } = storeToRefs(userStore)
  if (userTokenStorage.value) {
    try {
      userStore.setUserToken(userTokenStorage.value)
      await userStore.setUser(userTokenStorage.value)
    } catch (err) {
      throw new Error(err)
    }
  }
}
