<template>
  <div v-show="isPhone" class="status-bar"></div>
  <TheHeader v-if="hiddenView" :user="user" :headerPadding="headerPadding"/>
  <main class="main" :class="{'main--small': isSmallMain, 'hidden': menuIsActive, 'main__app': footerPadding}">
    <div :class="{'main__container': hiddenView}">
      <router-view v-slot="{ Component }">
        <keep-alive :exclude="excludeComponents">
          <component :is="Component"/>
        </keep-alive>
      </router-view>
      <div class="version">{{ appVersion }}</div>
    </div>
  </main>
  <HamburgerModal :hasRights="hasRights"/>
</template>

<script>
import 'swiper/css'
import 'swiper/css/pagination'
import Multiselect from 'vue-multiselect'
import TheHeader from '@/components/layout/header/TheHeader.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { authDevelopment } from '@/utilits/authDevelopment'
import { authBrowser } from '@/utilits/authBrowser'
import HamburgerModal from '@/components/layout/header/HamburgerModal.vue'
import { useUserStore } from '@/stores/UserStore'
import ClientsEditModal from '@/components/pages/clients/ClientsEditModal.vue'

export default {
  components: {
    TheHeader,
    HamburgerModal,
    Multiselect,
    ClientsEditModal
  },

  setup () {
    const appVersion = process.env.VUE_APP_VERSION
    const menuStore = useMenuStore()
    const { closeMenu, toggleMenu } = menuStore
    const { menuIsActive } = storeToRefs(menuStore)
    const userStore = useUserStore()
    const { user, hasRights } = storeToRefs(userStore)
    const route = useRoute()
    const specialRoute = ['login', 'finger', 'auth']
    const footerPadding = ref('')
    const headerPadding = ref('')
    const isSmallMain = computed(() => {
      return specialRoute.includes(route.name) || route.name === 'error'
    })
    const hiddenView = computed(() => {
      return !specialRoute.includes(route.name)
    })

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'android'
    })
    const excludeComponents = ['VisitsPlanView', 'VisitsFactsView', 'ContactsCardView']
    onMounted(async () => {
      await Promise.all([authDevelopment(), authBrowser()])
      if (isPhone.value) {
        const statusBar = document.querySelector('.status-bar')
        if (window?.device?.platform.toLowerCase() === 'android') {
          statusBar.style.height = '20px'
          footerPadding.value = '40px'
        } else if (window?.StatusBarHeight) {
          window.StatusBarHeight.getValue(
            function (value) {
              statusBar.style.height = value + 'px'
              headerPadding.value = value + 5 + 'px'
              if (value > 40) {
                footerPadding.value = '10px'
              }
            },
            function (error) {
              console.log(error)
              statusBar.style.height = '20px'
            }
          )
        }
      }
    })
    return {
      isSmallMain,
      hiddenView,
      menuIsActive,
      closeMenu,
      toggleMenu,
      footerPadding,
      headerPadding,
      isPhone,
      user,
      excludeComponents,
      appVersion,
      hasRights
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";
.version {
  margin-top: 20px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
